import { render, staticRenderFns } from "./InputNumber.vue?vue&type=template&id=33981676&"
import script from "./InputNumber.vue?vue&type=script&lang=ts&"
export * from "./InputNumber.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-2/_work/11/a/NuxtApp/components/base/Icon.vue').default})
